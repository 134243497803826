export var BROWSER;
(function (BROWSER) {
    BROWSER[BROWSER["IE"] = 1] = "IE";
    BROWSER[BROWSER["EDGE"] = 2] = "EDGE";
    BROWSER[BROWSER["FIREFOX"] = 3] = "FIREFOX";
    BROWSER[BROWSER["CHROME"] = 4] = "CHROME";
    BROWSER[BROWSER["SAFARI"] = 5] = "SAFARI";
    BROWSER[BROWSER["UNKNOWN"] = 6] = "UNKNOWN";
})(BROWSER || (BROWSER = {}));
export function parseBrowserName(userAgent) {
    if (/Edge\/[\w\.]*/.test(userAgent)) {
        // NOTE: EDGE hijacks chrome and safari user agents, must be before them
        // TODO: return BROWSER.EDGE instead, EDGE and IE will continue to diverge in the future
        return BROWSER.EDGE;
    }
    else if (/Chrome\/[\w\.]*/.test(userAgent)) {
        // NOTE: chrome hijacks safari user agent, must be before safari
        return BROWSER.CHROME;
    }
    else if (/Firefox\/[\w\.]*/.test(userAgent)) {
        return BROWSER.FIREFOX;
    }
    else if (/Safari\/[\w\.]*/.test(userAgent)) {
        return BROWSER.SAFARI;
    }
    else if (/MSIE /.test(userAgent) || /Trident\//.test(userAgent)) {
        return BROWSER.IE;
    }
    return BROWSER.UNKNOWN;
}
var browserId = null;
export function getBrowserName() {
    if (browserId === null) {
        browserId = parseBrowserName(window.navigator.userAgent);
    }
    return browserId;
}
